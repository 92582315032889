import { render, staticRenderFns } from "./OriginDestination.vue?vue&type=template&id=6577a73c&scoped=true&"
import script from "./OriginDestination.vue?vue&type=script&lang=js&"
export * from "./OriginDestination.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6577a73c",
  null
  
)

export default component.exports