<template>
  <div>
    <div class="row q-my-md q-gutter-md">
      <div class="col">
        <date-field
          v-model="value.outbound"
          :min="minDepart"
          :label="labels.outbound.date"
          rounded
          outlined
          @input="v => onChange(v, 'outbound')"
        />
      </div>
      <div v-if="time" class="col">
        <time-field
          :value="value.outbound"
          :min="minDepart"
          :minute-step="15"
          :label="labels.outbound.time"
          rounded
          outlined
          @input="v => onChange(v, 'outbound')"
        />
      </div>
    </div>
    <template v-if="returning || checkOut">
      <div class="row q-gutter-md">
        <div class="col">
          <date-field
            :value="value.inbound"
            :min="minReturn"
            :label="labels.inbound.date"
            rounded
            outlined
            @input="v => onChange(v, 'inbound')"
          />
        </div>
        <div v-if="time" class="col">
          <time-field
            :value="value.inbound"
            :min="minReturn"
            :minute-step="15"
            :label="labels.inbound.time"
            rounded
            outlined
            @input="v => onChange(v, 'inbound')"
          />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import dateField from '@/MFields/date'
import timeField from '@/MFields/time'
import date from 'utils/date-time'
const { addToDate, roundMinutesUp } = date
export default {
  components: {
    dateField,
    timeField
  },
  props: {
    checkOut: {
      type: Boolean,
      default: false
    },
    time: {
      type: Boolean,
      default: true
    },
    smart: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => ({
        outbound: {},
        inbound: {}
      })
    },
    minReturn: {
      type: Object,
      default: () =>
        roundMinutesUp(addToDate(date.newDate(), { minutes: 15 }), 15)
    },
    minDepart: {
      type: Object,
      default: () =>
        roundMinutesUp(addToDate(date.newDate(), { minutes: 15 }), 15)
    },
    returning: {
      type: Boolean,
      default: false
    },
    labels: {
      type: Object
    }
  },
  data () {
    return {
      inbound: null,
      outbound: null
    }
  },
  methods: {
    onChange (value, key) {
      if (key === 'outbound' && this.value.inbound !== null && (this.value.outbound > this.value.inbound)) {
        this.$emit('input', { outbound: value, inbound: addToDate(value, { hours: 4 }) }, 'date')
      } else {
        this.$emit('input', { ...this.value, [key]: value }, 'date')
      }
    }
  }
}
</script>
