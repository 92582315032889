<template>
  <q-page class="" padding>
    <on-demand-form
      :options="options"
      :location-query="placeSearch"
      :mapper="placeMapper"
      :user="user"
      :initial="initial"
      :labels="labels"
      :addresses="{saved, recent}"
      no-return
      time
      type="fa-subway"
      @submit="onSubmit"
    />
  </q-page>
</template>

<script>
// TODO add script to container with slot for template!
import date from 'utils/date-time'
import OnDemandForm from '../form/'
import { placeMapper } from '../form/mappers'
import { query as placeSearch } from 'api/places'
import { mapGetters } from 'vuex'
export default {
  components: {
    OnDemandForm
  },
  data () {
    return {
      labels: {
        location: {
          origin: this.$t('going_from'),
          destination: this.$t('going_to')
        },
        submit: this.$tc('find.tram')
      },
      initial: {
        departMin: date.newDate(),
        origin: {},
        destination: {},
        date: {
          outbound: date.addToDate(date.newDate(), { minutes: 5 }),
          inbound: date.addToDate(date.newDate(), { minutes: 5 })
        },
        options: {}
      },
      options: {},
      returning: true,
      locationResults: null,
      activeInput: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'userLookup',
      recent: 'addresses/recent',
      saved: 'addresses/saved'
    }),
    disabled () {
      if (!this.parameters.origin || !this.parameters.destination) {
        return true
      }
      return false
    }
  },
  watch: {
    '$route.params.origin': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.initial.origin = val
        }
      }
    }
  },
  methods: {
    placeSearch,
    placeMapper,
    onSubmit (values) {
      const mapped = {
        ...values,
        depart: values.date.outbound,
        return: values.date.inbound
      }
      this.$store.dispatch('ondemand/stash', { parameters: mapped })
      this.$router.push({ name: 'ondemand-tram-routes' })
    }
  }
}
</script>
