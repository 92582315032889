<template>
  <div class="row origin-dest" :style="single ? '' : {height: '130px'}">
    <div class="col">
      <div class="row">
        <div class="col">
          <q-input
            ref="origin"
            name="origin"
            autocomplete="off"
            :value="origin.label"
            :placeholder="labels.origin"
            outlined
            rounded
            @focus="onFocus"
            @blur="$emit('blur')"
            @input="onChange($event, 'origin')"
          >
            <template v-slot:before>
              <q-icon name="fas fa-location" color="primary" />
            </template>
            <template v-if="origin.label" v-slot:append>
              <q-icon name="cancel" class="cursor-pointer" color="primary" @click.stop="$emit('clear', 'origin')" />
            </template>
          </q-input>
        </div>
      </div>
      <div v-if="!single" class="row">
        <div class="col">
          <q-input
            ref="destination"
            name="destination"
            autocomplete="off"
            outlined
            rounded
            :value="destination.label"
            :placeholder="labels.destination"
            @focus="onFocus"
            @blur="$emit('blur')"
            @input="onChange($event, 'destination')"
          >
            <template v-slot:before>
              <q-icon name="fas fa-map-marker" color="primary" />
            </template>
            <template v-if="destination.label" v-slot:append>
              <q-icon name="cancel" class="cursor-pointer" color="primary" @click.stop="$emit('clear', 'destination')" />
            </template>
          </q-input>
        </div>
      </div>
    </div>
    <div v-if="!single" class="col-1 flex justify-end items-center" @click="$emit('swap')">
      <q-icon name="fa fa-sort-alt" size="24px" color="primary" />
    </div>
  </div>
</template>
<script>
import i18n from 'i18n'
import { handleErrors } from 'utils/utils'

export default {
  name: 'OriginDestination',
  props: {
    single: {
      type: Boolean,
      default: false
    },
    origin: {
      type: Object,
      default: () => {}
    },
    destination: {
      type: Object,
      default: () => {}
    },
    // Search query to call
    locationQuery: {
      type: Function,
      default: () => {}
    },
    // Used to map the query result with the input
    mapper: {
      type: Function,
      default: () => {}
    },
    labels: {
      type: Object,
      default: () => ({
        origin: i18n.t('input.origin'),
        destination: i18n.t('input.destination')
      })
    }

  },
  data () {
    return {
      inputValues: {
        destination: '',
        origin: ''
      },
      actualValues: {
        origin: {},
        destination: {}
      }
    }
  },
  watch: {
    origin: {
      deep: true,
      handler (val, old) {
        // Sets label of origin input
        this.inputValues.origin = val.name
      }
    },
    destination: {
      deep: true,
      handler (val, old) {
        // Sets label of destination input
        this.inputValues.destination = val.name
      }
    }
  },
  mounted () {
    if (this.origin.label) {
      if (!this.single) this.$refs.destination.focus()
    } else {
      this.$refs.origin.focus()
    }
  },
  methods: {
    onFocus (element) {
      const name = element.target.name
      if (this[name]?.label?.toLowerCase() === 'current location') this.$emit('clear', name)
      this.$emit('focus', element)
    },
    async onChange (value, key) {
      this.actualValues = {
        ...this.actualValues,
        [key]: value
      }
      try {
        const res = await this.locationQuery({ query: value })
        let mapped
        if (res.data) {
          mapped = this.mapper(res.data)
        } else {
          mapped = res
        }
        this.$emit('search', mapped)
      } catch (err) {
        handleErrors(err)
      }
    },
    clearField (key) {
      this.actualValues = {
        ...this.actualValues,
        [key]: {}
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
</style>
