<template>
  <form>
    <origin-destination
      :origin="parameters.origin"
      :destination="parameters.destination"
      :location-query="locationQuery"
      :mapper="mapper"
      :single="single"
      :labels="lbles.location"
      @focus="onFocus"
      @search="onLocationSearch"
      @swap="swap"
      @clear="clear"
    />
    <template />
    <location-list
      v-if="listOpen"
      :addresses="locList"
      class="q-my-md"
      :type="type"
      :style="{top: single ? '90px' : '130px'}"
      @close="close"
      @input="onChange"
    />
    <template v-if="!smart">
      <div v-if="((onlyReturn || rental) || !checkOut || !noReturn) && !noOneWayReturnToggle" class="row q-my-md">
        <slot name="return" :formReturn="returning">
          <div class="col-6 center">
            <q-radio v-model="returning" :val="false" :label="$tc('one_way_return')" />
          </div>
          <div class="col-6 center">
            <q-radio v-model="returning" :val="true" :label="$tc('one_way_return', 2)" />
          </div>
        </slot>
      </div>
      <date-time-o-d
        :value="{ ...parameters.date }"
        name="date"
        :returning="onlyReturn || returning"
        :check-out="checkOut"
        class="q-my-md"
        :time="time"
        :labels="{outbound: lbles.outbound, inbound: lbles.inbound}"
        :smart="smart"
        @input="onChangeDate"
      />
      <template v-for="(option, key) in options">
        <div :key="key" class="row q-col-gutter-sm q-mt-md">
          <div class="col">
            <q-select
              v-model="parameters.options[key]"
              :options="option"
              stack-label
              :label="optionLabel(key)"
              map-options
              emit-value
              :name="key"
              :rounded="$q.platform.is.mobile"
              :outlined="$q.platform.is.mobile"
              @input="v => onChange(v, key)"
            />
          </div>
        </div>
      </template>
      <template v-if="middle">
        <div class="row">
          <div class="col">
            <m-unified-users v-model="people" />
          </div>
        </div>
      </template>
      <railcard-select
        v-if="railcardOptions"
        class="row"
        :adults="parameters.options.adults"
        :railcard-options="railcardOptions"
        type="railcard"
        @update="val => railcards = val"
      />
      <q-footer class="cta bg-white">
        <q-btn
          :disabled="disabled"
          size="lg"
          color="primary"
          class="full-width"
          @click="submit"
        >
          {{ lbles.submit }}
        </q-btn>
      </q-footer>
    </template>
    <leave-now
      v-if="smart"
      :departing="parameters.date.outbound"
      :disabled="disabled"
      :is-guest="hasRole('guest')"
      @depart="value => parameters.date.outbound = value" @submit="submit"
    />
    <m-guest-blocker v-if="smart && hasRole('guest')" />
  </form>
</template>

<script>
import { isEmpty } from 'lodash'
import date from 'utils/date-time'
import OriginDestination from './OriginDestination'
import LocationList from './LocationList'
import DateTimeOD from './DateTime'
import authentication from 'mixins/authentication'
import railcardSelect from '../train/railcards'
import { MGuestBlocker, LeaveNow } from 'components/'

export default {
  components: {
    OriginDestination,
    LocationList,
    DateTimeOD,
    LeaveNow,
    railcardSelect,
    MGuestBlocker
  },
  mixins: [authentication],
  props: {
    // Icon for location list
    type: {
      type: String,
      default: 'fa-map-marker'
    },
    // Query to use to search origin/destination
    locationQuery: {
      type: Function,
      default: () => {}
    },
    // Mapper to run query results through
    mapper: {
      type: Function,
      default: () => ({})
    },
    // Travel options e.g adults, class, changes etc.
    options: {
      type: Object,
      default: () => ({})
    },
    // Initial state of form
    initial: {
      type: Object,
      default: () => ({})
    },
    onlyReturn: {
    // Show return time but don't let user select if return or one way. e.g car club
      type: Boolean,
      default: false
    },
    time: {
    // Whether to allow user to select a time
      type: Boolean,
      default: false
    },
    single: {
    // Whether form needs both origin + dest or just one
      type: Boolean,
      default: false
    },
    middle: {
    // Whether user is booking for someone else
      type: Boolean,
      default: false
    },
    user: {
    // Whether user is booking for someone else
      type: [String, Object],
      default: null
    },
    noReturn: {
    // Whether to show return or not
      type: Boolean,
      default: false
    },
    checkOut: {
    // Whether to show checkout
      type: Boolean,
      default: false
    },
    noOneWayReturnToggle: {
    // Disable the one-way/return toggle without affecting the return date fields
      type: Boolean,
      default: false
    },
    labels: {
      type: Object,
      default: () => ({

      })
    },
    // Whether or not to show saved & recent locations
    saved: {
      type: Boolean,
      default: true
    },
    rental: {
      // Silly hack to determine if there is a slot for the radio buttons
      type: Boolean,
      default: false
    },
    addresses: {
      type: Object,
      default: () => ({
        saved: [],
        recent: []
      })
    },
    smart: {
      type: Boolean,
      default: false
    },
    isCarClub: {
      type: Boolean,
      default: false
    },
    railcardOptions: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      dateChanged: false,
      labelDefault: {
        location: {
          origin: this.$t('input.starting_location'),
          destination: this.$t('input.destination'),
          icons: {
            origin: 'places',
            destination: 'places'
          }
        },
        inbound: {
          date: this.$t('date.return'),
          time: this.$t('time.return')
        },
        outbound: {
          date: this.$t('date.travel'),
          time: this.$t('time.from')
        },
        submit: this.$t('search')
      },
      parameters: {
        departMin: date.newDate(),
        origin: {},
        destination: {},
        date: {
          outbound: date.toCivilDateTime(date.addToDate(date.newDate(), { minutes: 15 })),
          inbound: null
        },
        options: {},
        traveller: this.middle || this.user,
        requester: this.middle || this.user,
        ...this.initial
      },
      railcards: [],
      returning: false,
      locationResults: [],
      activeInput: '',
      openList: false
    }
  },
  computed: {
    listOpen () {
      if (this.saved && this.locList.saved.length > 0) {
        return this.openList
      } else {
        return this.locationResults.length > 0
      }
    },
    leaveNow () {
      return this.dateChanged ? date.toCivilTime(this.parameters.date.outbound) : this.$t('smartsearch.choose_a_time')
    },
    lbles () {
      return Object.assign({}, this.labelDefault, this.labels)
    },
    disabled () {
      if (this.rental || this.noReturn || this.checkOut) {
        if (this.returning || this.smart) {
          if (isEmpty(this.parameters.origin) || isEmpty(this.parameters.destination)) {
            return true
          }
        } else {
          if (isEmpty(this.parameters.origin)) {
            return true
          }
        }
      } else if (isEmpty(this.parameters.origin) || (isEmpty(this.parameters.destination) && !this.isCarClub)) {
        return true
      }
      return false
    },
    locList () {
      return ({
        locations: this.locationResults,
        saved: this.saved ? this.addresses.saved : [],
        recent: this.saved ? this.addresses.recent : []
      })
    }
  },
  watch: {
    returning: function (value) {
      if (!value && !this.rental) {
        this.parameters.date.inbound = null
      } else {
        this.parameters.date.inbound = date.addToDate(this.parameters.date.outbound, { hours: 4 })
      }
    }
  },
  methods: {
    close () {
      this.openList = false
      this.locationResults = []
    },
    saveTime () {
      this.dateChanged = true
      this.parameters.date.outbound = this.proxyTime
    },
    updateDate () {
      this.proxyTime = this.parameters.date.outbound
    },
    optionLabel (key) {
      const label = key.replace('_', ' ')
      return label.charAt(0).toUpperCase() + label.slice(1)
    },
    swap () {
      [this.parameters.origin, this.parameters.destination] = [this.parameters.destination, this.parameters.origin]
    },
    onFocus (element) {
      const name = element.target.name
      this.activeInput = name
      this.openList = true
    },
    clear (key) {
      this.parameters[key] = {}
      this.activeInput = key
    },
    onLocationSearch (results) {
      this.locationResults = results
    },
    onChange (value, key) {
      const keyToAdd = key || this.activeInput
      // All changes go through here
      this.parameters = {
        ...this.parameters,
        [keyToAdd]: value
      }
      this.$emit('input', this.parameters)
      this.locationResults = []
      this.openList = false
    },
    onChangeDate (value, key) {
      this.activeInput = 'date'
      this.onChange(value, key)
    },
    submit () {
      // Flattens parameters to be backwards compatible with non-mobile forms
      const mapped = {
        ...this.parameters,
        ...this.parameters.options,
        user: this.user,
        railcards: this.railcards
      }
      this.$emit('submit', mapped)
    }
  }
}
</script>

<style lang="stylus" scoped>
form
  height calc(100vh - 160px)
  overflow-y scroll
.cta
  padding 10px
</style>
