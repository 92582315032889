<template>
  <q-list>
    <template v-for="(addressType, key) in items">
      <q-item-label v-if="addressType.length" :key="key" header class="text-capitalize key">
        {{ key }}
      </q-item-label>
      <div v-if="addressType.length" :key="key+'col'" style="height:100%">
        <q-scroll-area :key="key+'scroll'" style="height:100%">
          <template v-for="(address, index) in addressType">
            <q-item :key="address.label+ index" v-ripple clickable @click="selected(key, index)">
              <q-item-section avatar>
                <q-avatar :icon="type === 'fas fa-car' ? address.icon : `fa ${type}`" text-color="primary" />
              </q-item-section>

              <q-item-section>
                <q-item-label>{{ address.name }}</q-item-label>
                <q-item-label caption lines="2">
                  {{ address.label }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-scroll-area>
        <q-separator v-if="addressType.length" :key="key+'separator'" spaced />
      </div>
    </template>

    <q-btn
      v-ripple
      color="primary"
      class="close"
      @click="close"
    >
      {{ $t('close') }}
    </q-btn>
  </q-list>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: 'fa-map-marker'
    },
    addresses: {
      type: Object,
      default: () => ({
        locations: [],
        saved: [],
        recent: []
      })
    }
  },
  computed: {
    items () {
      const remap = arr => {
        return arr.map(address => {
          const lat = address.address?.lat || address.latlng.lat
          const lng = address.address?.lng || address.latlng.lng
          const mappedLocation = {
            name: address.address ? address.address.label : address.label || this.$t('unnamed_address'),
            label: address.text || address.label,
            value: `${lat},${lng}`
          }

          return mappedLocation
        })
      }

      return ({
        locations: this.addresses.locations,
        saved: this.addresses.saved ? remap(this.addresses.saved) : [],
        recent: this.addresses.recent ? remap(this.addresses.recent) : []
      })
    }
  },
  methods: {
    selected (type, index) {
      this.$emit('input', this.items[type][index])
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
<style  lang="stylus" scoped>
  .q-list
    position: absolute
    background-color: white
    overflow: auto;
    bottom: 0
    top: 130px
    left: 5%
    width: 90%;
    z-index: 10000
    height: 60vh
    display flex
    flex-direction column
    box-shadow 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0 0 0 0.14), 0 3px 1px -2px rgba(0 0 0 0.12)

  .close
    position: sticky
    width: 100%

  .key
    font-size 125%
</style>
